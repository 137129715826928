<template>
  <v-container fluid>
    <v-card v-if="item && connector">
      <v-toolbar class="mb-2" color="primary" dark flat>
        <v-toolbar-title>
          Charging Station {{ item.uniqueId }} - Connector {{ connector.connectorId }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <x-btn :x-options="refreshBtnOptions" :x-context="self"></x-btn>
        <x-btn :x-options="startChargingBtnOptions" :x-context="self" :key="`start-${connector.status}`"
          v-if="['Available', 'Preparing'].includes(connector.status)"></x-btn>
        <x-btn :x-options="stopChargingBtnOptions" :x-context="self" :key="`stop-${connector.status}`"
          v-if="['Charging'].includes(connector.status)"></x-btn>
      </v-toolbar>
      <loading v-if="initializeLoading"></loading>
      <div class="red--text text-center" v-else-if="initializeError">
        {{ initializeError }}
      </div>
      <template v-else-if="latestChargingSession">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Field</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  <v-chip label outlined color="primary">{{ connector.status }}</v-chip>
                </td>
              </tr>
              <template v-if="(latestChargingSession.details &&
        (!latestChargingSession.details.stop ||
          !latestChargingSession.stopTime ||
          latestChargingSession.stopTime < latestChargingSession.startTime)) ||
      latestChargingSessionUpdated
      ">
                <template v-if="latestChargingSession.details">
                  <template v-if="!['Available', 'Preparing'].includes(connector.status)">
                    <tr>
                      <td>Charging Power</td>
                      <td>
                        {{ (meterValue("Power.Active.Import") / 1000) | number3(2, 0) }}
                        kW
                      </td>
                    </tr>
                    <tr>
                      <td>SoC</td>
                      <td>
                        {{
      (latestChargingSession.details.stop &&
        latestChargingSession.stopTime > latestChargingSession.startTime &&
        latestChargingSession.details.stateOfCharge) ||
      latestChargingSession.details.currentStateOfCharge ||
      latestChargingSession.details.stateOfCharge ||
      0
    }}
                        %
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td>Energy Delivered</td>
                    <td>
                      {{
        (((latestChargingSession.details.stop &&
          latestChargingSession.stopTime > latestChargingSession.startTime &&
          latestChargingSession.details.stop.totalConsumptionWh) ||
          latestChargingSession.details.currentTotalConsumptionWh ||
          0) /
          1000)
        | number3(2, 0)
      }}
                      kWh
                    </td>
                  </tr>
                </template>
                <template v-if="latestChargingSession.details.stop &&
      latestChargingSession.stopTime > latestChargingSession.startTime
      ">
                  <tr>
                    <td>Start Time</td>
                    <td>{{ latestChargingSession.startTime | dateTime }}</td>
                  </tr>
                  <tr>
                    <td>Stop Time</td>
                    <td>{{ latestChargingSession.stopTime | dateTime }}</td>
                  </tr>
                  <tr>
                    <td>Duration</td>
                    <td>{{ (latestChargingSession.details.stop.totalDurationSecs * 1000) | duration }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td>Start Time</td>
                    <td>{{ latestChargingSession.startTime | dateTime }}</td>
                  </tr>
                  <tr>
                    <td>Duration</td>
                    <td>{{ currentDuration | duration }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <div class="py-4 px-10">
          <x-btn
            v-if="addPointBtnOptions && latestChargingSession && latestChargingSession.stopTime && !latestChargingSession.isPaid"
            small block color="primary" :x-options="addPointBtnOptions" :x-context="self"></x-btn>
        </div>
      </template>
      <template v-else>
        <v-card-text class="text-center"> No charging session found </v-card-text>
      </template>
    </v-card>
    <loading v-else-if="initializeLoading"></loading>
    <div class="red--text text-center" v-else-if="initializeError">
      {{ initializeError }}
    </div>
    <div class="text-center" v-else>Charging Station or Connector not found</div>

  </v-container>
</template>

<script>
import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import centrifugeClientFactory from "@/services/centrifugeClientFactory";
import merge from "@common/modules/vuetifyx/utils/merge";
import Loading from "@/components/Loading.vue";
import appService from "@/services/appService";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import PaymentMethodInputVue from "./settings/partials/payment-transactions/PaymentMethodInput.vue";
import PointInputVue from "./settings/partials/payment-transactions/PointInput.vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      self: this,
      tenantSetting: null,
      addPointBtnOptions: null,
      refreshBtnOptions: {
        attrs: {
          text: true,
        },
        content: {
          icon: "mdi-refresh",
        },
        on: {
          async xClick({ self }) {
            self.context().initialize();
          },
        },
        target: {
          tooltip: {
            content: {
              text: "Refresh",
            },
          },
        },
      },
      startChargingBtnOptions: {
        attrs: {
          text: true,
        },
        content: {
          icon: "mdi-play",
        },
        target: {
          tooltip: {
            content: {
              text: "Start Charging",
            },
          },
          confirm: {
            content: {
              content: {
                html: "Are you sure you want to start charging?",
              },
            },
            on: {
              async xYes({ self }) {
                const yesBtn = self.yesBtnContainer.value;
                const btn = self.context();
                const { item, connector } = btn.context();
                yesBtn.loading.value = true;
                const result = await coreApiClient.call("chargingStations", "startCharging", {
                  id: item.id,
                  connectorId: connector.connectorId,
                });
                yesBtn.loading.value = false;
                if (result) {
                  self.hide();
                }
              },
            },
          },
        },
      },
      stopChargingBtnOptions: {
        attrs: {
          text: true,
        },
        content: {
          icon: "mdi-stop",
        },
        target: {
          tooltip: {
            content: {
              text: "Stop Charging",
            },
          },
          confirm: {
            content: {
              content: {
                html: "Are you sure you want to stop charging?",
              },
            },
            on: {
              async xYes({ self }) {
                const yesBtn = self.yesBtnContainer.value;
                const btn = self.context();
                const { item, connector } = btn.context();
                yesBtn.loading.value = true;
                const result = await coreApiClient.call("chargingStations", "stopCharging", {
                  id: item.id,
                  connectorId: connector.connectorId,
                });
                yesBtn.loading.value = false;
                if (result) {
                  self.hide();
                }
              },
            },
          },
        },
      },
      initializeLoading: false,
      initializeError: null,
      item: null,
      connector: null,
      latestChargingSession: null,
      latestChargingSessionUpdated: false,
      currentDuration: null,
    };
  },
  methods: {
    makeAddPointBtnOptions(point, sessionIdPayment) {
      const amountPoint = Math.round(point);
      return {
        attrs: {
          color: "primary",
          dark: false,
          small: true,
          class: "ml-4",
          height: "30px",
        },
        content: {
          text: "Payment Last Session",
        },
        target: {
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            attrs: {
              width: "700px",
            },
            content: {
              toolbar: {
                title: "Payment Last Session",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            point: {
                              type: PointInputVue,
                              attrs: {
                                label: "Amount",
                                hideLabel: true,
                                required: true,
                                items: [amountPoint],
                                tenantSetting: this.tenantSetting,
                              },
                              ext: {
                                defaultValue: amountPoint,
                              },
                            },
                            paymentMethod: {
                              type: PaymentMethodInputVue,
                              attrs: {
                                label: "Payment Method",
                                hideLabel: true,
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                cancel: {
                  ext: {
                    hidden: true,
                  },
                },
                save: {
                  attrs: {
                    block: true,
                    text: false,
                  },
                  content: {
                    text: "Pay",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      // const { tenantSetting } = dialog.context().context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      let apiName = "";
                      switch (data.paymentMethod.type) {
                        case "vnpay":
                          apiName = "integrationVnpay";
                          break;
                        case "momo":
                          apiName = "integrationMomo";
                          break;
                        default:
                          apiName = "integrationVnpay";
                          break;
                      }

                      const amount = data.point;

                      self.loading.value = true;
                      const result = await coreApiClient.call(apiName, "createPaymentUrl", {
                        amount,
                        id: data.paymentMethod.id,
                        device: "web",
                        sessionId: sessionIdPayment
                      });
                      self.loading.value = false;
                      if (result) {
                        location.href = result;
                      }
                    },
                  },
                },
              },
            },
          },
        },
      };
    },
    async initialize() {
      const id = this.$route.params.id;
      const connectorId = this.$route.params.connectorId;
      const self = this;

      setInterval(() => {
        this.currentDuration = this.getCurrentDuration();
      }, 1000);

      this.initializeLoading = true;
      try {
        const item = await coreApiClient.call("chargingStations", "findById", {
          id,
        });
        if (item) {
          this.item = item;
        }
        if (this.item) {
          const connector =
            this.item.details &&
            this.item.details.connectors &&
            this.item.details.connectors.find((c) => +c.connectorId === +connectorId);
          if (connector) {
            this.connector = connector;
          }
        }
        if (this.item) {
          let channelName = "";
          const activeTenant = appService.getActiveTenant();
          if (activeTenant) {
            channelName = `private-chargingStations-tenantId=${activeTenant._id}`;
          } else if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id}`;
          } else if (userManager.checkRole(["retailer"], true)) {
            channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id}-retailerAdminUserId=${
              userManager.getUserInfo()._id
            }`;
          } else if (userManager.checkRole(["user"], true)) {
            channelName = `private-chargingStations-tenantId=${
              userManager.getUserInfo().tenant._id
            }-connectorItemsActiveUserId=${userManager.getUserInfo()._id}`;
          } else {
            channelName = `private-chargingStations`;
          }
          const socketId = userManager.getUserInfo()._id;
          const centrifuge = centrifugeClientFactory(null, async () => {
            const {
              auth
            } = await coreApiClient.call("chargingStations", "subscribe", {
              socketId,
              channelName,
              connectorId,
              id: self.item._id,
            });
            return auth;
          });
          this.centrifuge = centrifuge;
          const sub = centrifuge
            .newSubscription(`private-chargingStations-tenantId=${this.item.tenantId}-uniqueId=${this.item.uniqueId}`)
          sub.on('publication', (ctx) => {
            const data = ctx.data;
            self.$set(self, "item", merge.clone(self.item, data));
            const connector =
              self.item.details &&
              self.item.details.connectors &&
              self.item.details.connectors.find((c) => +c.connectorId === +connectorId);
            if (connector) {
              self.$set(self, "connector", connector);
              if (["Available", "Preparing"].includes(connector.status)) {
                if (self.latestChargingSession && self.latestChargingSession.details) {
                  self.$set(self.latestChargingSession.details, "rawMeterValues", null);
                }
              }
            }
          });
          sub.subscribe();
          const subSession = centrifuge
            .newSubscription(
              `private-chargingSessions-tenantId=${this.item.tenantId}-chargingStationId=${this.item._id}-connectorId=${connectorId}`
            )
          subSession.on("publication", (ctx) => {
            const data = ctx.data;
            self.$set(self, "latestChargingSession", merge.clone(self.latestChargingSession || {}, data));
            self.$set(self, "latestChargingSessionUpdated", true);
            self.makeAddPointBtnOptions(self.latestChargingSession.point, self.latestChargingSession._id);
          });
          subSession.subscribe();
          centrifuge.connect();
        }
        if (this.connector) {
          this.latestChargingSession = await coreApiClient.call("chargingSessions", "findOne", {
            payload: JSON.stringify({
              orderBy: "startTime",
              orderType: "desc",
              filters: [
                {
                  key: "chargingStationId",
                  operator: "equal_to",
                  value: id,
                },
                {
                  key: "connectorId",
                  operator: "equal_to",
                  value: +connectorId,
                },
              ],
            }),
          });
        }
      } catch (e) {
        this.initializeError = e;
      } finally {
        this.initializeLoading = false;
      }
    },
    meterValue(key) {
      if (!this.latestChargingSession || !this.latestChargingSession.details) {
        return 0;
      }
      if (
        this.latestChargingSession.details.rawMeterValues &&
        this.latestChargingSession.details.rawMeterValues.meterValues &&
        this.latestChargingSession.details.rawMeterValues.meterValues.meterValue &&
        this.latestChargingSession.details.rawMeterValues.meterValues.meterValue[0] &&
        this.latestChargingSession.details.rawMeterValues.meterValues.meterValue[0].sampledValue
      ) {
        const arr = this.latestChargingSession.details.rawMeterValues.meterValues.meterValue[0].sampledValue;
        const item = arr.find((it) => it.measurand === key);
        if (item) {
          return parseFloat(item.value);
        }
      }
      return 0;
    },
    getCurrentDuration() {
      if (!this.latestChargingSession) {
        return "";
      }
      return new Date().getTime() - this.latestChargingSession.startTime;
    },
  },
  async created() {
    await this.initialize();
  },
  destroyed() {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
  },
});
</script>