var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.item && _vm.connector)?_c('v-card',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" Charging Station "+_vm._s(_vm.item.uniqueId)+" - Connector "+_vm._s(_vm.connector.connectorId)+" ")]),_c('v-spacer'),_c('x-btn',{attrs:{"x-options":_vm.refreshBtnOptions,"x-context":_vm.self}}),(['Available', 'Preparing'].includes(_vm.connector.status))?_c('x-btn',{key:("start-" + (_vm.connector.status)),attrs:{"x-options":_vm.startChargingBtnOptions,"x-context":_vm.self}}):_vm._e(),(['Charging'].includes(_vm.connector.status))?_c('x-btn',{key:("stop-" + (_vm.connector.status)),attrs:{"x-options":_vm.stopChargingBtnOptions,"x-context":_vm.self}}):_vm._e()],1),(_vm.initializeLoading)?_c('loading'):(_vm.initializeError)?_c('div',{staticClass:"red--text text-center"},[_vm._v(" "+_vm._s(_vm.initializeError)+" ")]):(_vm.latestChargingSession)?[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Field")]),_c('th',{staticClass:"text-left"},[_vm._v("Value")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Status")]),_c('td',[_c('v-chip',{attrs:{"label":"","outlined":"","color":"primary"}},[_vm._v(_vm._s(_vm.connector.status))])],1)]),((_vm.latestChargingSession.details &&
      (!_vm.latestChargingSession.details.stop ||
        !_vm.latestChargingSession.stopTime ||
        _vm.latestChargingSession.stopTime < _vm.latestChargingSession.startTime)) ||
    _vm.latestChargingSessionUpdated
    )?[(_vm.latestChargingSession.details)?[(!['Available', 'Preparing'].includes(_vm.connector.status))?[_c('tr',[_c('td',[_vm._v("Charging Power")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number3")((_vm.meterValue("Power.Active.Import") / 1000),2, 0))+" kW ")])]),_c('tr',[_c('td',[_vm._v("SoC")]),_c('td',[_vm._v(" "+_vm._s((_vm.latestChargingSession.details.stop && _vm.latestChargingSession.stopTime > _vm.latestChargingSession.startTime && _vm.latestChargingSession.details.stateOfCharge) || _vm.latestChargingSession.details.currentStateOfCharge || _vm.latestChargingSession.details.stateOfCharge || 0)+" % ")])])]:_vm._e(),_c('tr',[_c('td',[_vm._v("Energy Delivered")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number3")((((_vm.latestChargingSession.details.stop && _vm.latestChargingSession.stopTime > _vm.latestChargingSession.startTime && _vm.latestChargingSession.details.stop.totalConsumptionWh) || _vm.latestChargingSession.details.currentTotalConsumptionWh || 0) / 1000),2, 0))+" kWh ")])])]:_vm._e(),(_vm.latestChargingSession.details.stop &&
    _vm.latestChargingSession.stopTime > _vm.latestChargingSession.startTime
    )?[_c('tr',[_c('td',[_vm._v("Start Time")]),_c('td',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.latestChargingSession.startTime)))])]),_c('tr',[_c('td',[_vm._v("Stop Time")]),_c('td',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.latestChargingSession.stopTime)))])]),_c('tr',[_c('td',[_vm._v("Duration")]),_c('td',[_vm._v(_vm._s(_vm._f("duration")((_vm.latestChargingSession.details.stop.totalDurationSecs * 1000))))])])]:[_c('tr',[_c('td',[_vm._v("Start Time")]),_c('td',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.latestChargingSession.startTime)))])]),_c('tr',[_c('td',[_vm._v("Duration")]),_c('td',[_vm._v(_vm._s(_vm._f("duration")(_vm.currentDuration)))])])]]:_vm._e()],2)]},proxy:true}],null,false,4133493524)}),_c('div',{staticClass:"py-4 px-10"},[(_vm.addPointBtnOptions && _vm.latestChargingSession && _vm.latestChargingSession.stopTime && !_vm.latestChargingSession.isPaid)?_c('x-btn',{attrs:{"small":"","block":"","color":"primary","x-options":_vm.addPointBtnOptions,"x-context":_vm.self}}):_vm._e()],1)]:[_c('v-card-text',{staticClass:"text-center"},[_vm._v(" No charging session found ")])]],2):(_vm.initializeLoading)?_c('loading'):(_vm.initializeError)?_c('div',{staticClass:"red--text text-center"},[_vm._v(" "+_vm._s(_vm.initializeError)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v("Charging Station or Connector not found")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }